import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Arrow from "../svgs/arrow-right.svg"
import "./plain-text.css"

const Text = ({ children }) => <p className="content">{children}</p>

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.HYPERLINK]: (node, children) => {
      const destination = node && node.data && node.data.uri
      return (
        <a href={destination} className="inline-link">
          {children}
        </a>
      )
    },
  },
}

class PlainText extends React.Component {
  render() {
    const { title, primaryText, secondaryText, image } = this.props
    const description = documentToReactComponents(primaryText, options)
    const supplement = documentToReactComponents(secondaryText, options)
    return (
      <div className="plain-text-container">
        <h3>{title}</h3>
        {image && (
          <img alt={image.description} className="img" src={image.fluid.src} />
        )}
        <span className="supplement">{description}</span>
        <span className="supplement">
          <Arrow className="arrow-right" />
          {supplement}
        </span>
      </div>
    )
  }
}

export default PlainText
