import React, { useState, useEffect, useRef } from "react"
import { Transition } from "react-transition-group"
import TransitionLink from "gatsby-plugin-transition-link"
import useNativeLazyLoading from "@charlietango/use-native-lazy-loading"
import { useInView } from "react-intersection-observer"
import { useSpring, animated } from "react-spring"
import gsap from "gsap"
import ArrowLarge from "../svgs/arrow-right-large.svg"
import { slug } from "../utils/url.js"
// import Marquee from "./marquee"

import "./video.css"

// for video hover
const duration = 100
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

// for videos playing on hover
const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const Video = ({ project, context }) => {
  const [state, setState] = useState({
    onHover: false,
  })
  const link = slug(`${project.brand}-${project.projectTitle}`)
  const linkDest = `/project/${link}`
  const [paddingBottom, setPaddingBottom] = useState(
    `${(project.projectImage.fixed.height / project.projectImage.fixed.width) * 100}%`
  )
  const supportsLazyLoading = useNativeLazyLoading()
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "60px 0px",
  })

  const vid = useRef(null)

  const onMouseEnter = () => {
    setState({
      onHover: true,
    })
    if (vid.current) {
      try {
        vid.current.play()
      } catch (err) {
        console.warn(err)
      }
    }
  }

  const onMouseLeave = () => {
    setState({
      onHover: false,
    })
    if (vid.current) {
      vid.current.pause()
    }
  }
  const props = useSpring({
    delay: 150,
    opacity: inView || supportsLazyLoading ? 1 : 0,
    transform: inView || supportsLazyLoading ? `translate3d(0,0%,0)` : `translate3d(0, 15%,0)`,
    easing: `cubic-bezier(0.5, 0, 0, 1)`,
  })

  useEffect(() => {
    const vids = document.querySelectorAll(".video video")
    if (vids.length) {
      vids.forEach(vid => {
        if (!vid.src) {
          vid.src = vid.getAttribute("data-src")
        }
      })
    }
  })

  let isMobile = false
  if (typeof window !== "undefined") {
    if (navigator && navigator.userAgent) {
      isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false
    }
  }

  const base = (
    <React.Fragment>
      <animated.div style={props} ref={!supportsLazyLoading ? ref : undefined}>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            paddingBottom: paddingBottom,
          }}
        >
          {inView || supportsLazyLoading ? (
            <img
              id={link}
              style={{ width: `100%`, position: "absolute" }}
              alt={project.projectTitle}
              src={project.projectImage.fluid.src}
              loading="lazy"
            />
          ) : null}
        </div>
        {/* <Marquee
          onHover={state.onHover}
          title={project.projectTitle}
          year={project.year || "2020"}
          client={project.brand}
        /> */}
        {project.condensedVideo && (
          <Transition in={state.onHover} timeout={duration}>
            {state => {
              if (vid.current) {
                vid.current.addEventListener(
                  "loadedmetadata",
                  e => {
                    setPaddingBottom(`${(e.target.videoHeight / e.target.videoWidth) * 100}%`)
                  },
                  false
                )
              }
              return (
                <video
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state],
                  }}
                  ref={vid}
                  loop
                  muted
                  data-src={project.condensedVideo.file.url}
                />
              )
            }}
          </Transition>
        )}
      </animated.div>
      <div className="video-info-container">
        <span className="video-title">
          {" "}
          <ArrowLarge className="arrow-right" style={{ height: `0.75em`, marginRight: `var(--f-body-small)` }} />
          {project.brand}
        </span>
        <span className="video-client">{project.projectTitle}</span>
      </div>
    </React.Fragment>
  )
  return (
    <div className="video">
      {!isMobile ? (
        <TransitionLink
          className="target"
          to={linkDest}
          onMouseOver={onMouseEnter}
          onMouseOut={onMouseLeave}
          exit={{ length: 1 }}
          entry={{ appearAfter: 1 }}
          trigger={async pages => {
            const exit = await pages.exit
            const fromEl = exit.node.querySelector(`#${link}`)
            gsap.set(exit.node.querySelector(".filter-container"), {
              "z-index": -1,
            })
            gsap.set(exit.node.querySelector("header"), {
              "z-index": -1,
            })
            if (context && context.getScrollbar) {
              requestAnimationFrame(function () {
                context.getScrollbar(scrollbar => {
                  scrollbar.scrollIntoView(fromEl, {
                    offsetLeft: 5000,
                  })
                  gsap.set(fromEl, {
                    position: `absolute`,
                  })
                  gsap.to(fromEl, {
                    width: `100vw`,
                    right: -fromEl.getBoundingClientRect().left,
                    top: 0,
                  })
                })
              })
            } else {
              gsap.to(exit.node, {
                opacity: 0,
              })
            }
          }}
        >
          {base}
        </TransitionLink>
      ) : (
        <TransitionLink className="target" to={linkDest}>
          {base}
        </TransitionLink>
      )}
    </div>
  )
}

export default Video
