import 'focus-visible';
import React from "react"
import Home from "../components/home"
import SEO from "../components/seo"

const IndexPage = ({ location }) => {
  return (
    <Home location={location}>
      <SEO title="Home" />
    </Home>
  )
}

export default IndexPage
