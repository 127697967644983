import React, { Component } from "react"
import Close from "../svgs/close.svg"
import "./filter.css"
import { Trail, animated } from "react-spring/renderprops"

class Filter extends Component {
  constructor() {
    super()
    this.state = {
      toggled: false,
    }

    this.onToggle = this.onToggle.bind(this)
    this.onFilter = this.onFilter.bind(this)
  }

  onFilter(e) {
    let { target } = e
    if (target.nodeName === "BUTTON") {
      this.props.onFilter(e)
      this.onToggle()
    }
  }

  onToggle() {
    document
      .querySelector(".filter-toggle")
      .classList.toggle("filter-list-open")
    this.setState({
      toggled: !this.state.toggled,
    })
  }

  render() {
    const labels = this.props.labels
    const filterOn = labels.some(item => item.active && item.label !== "all")
    return (
      <aside
        className={`filter-container ${
          this.props.isScrolled ? "scrolled" : ""
        }`}
      >
        <div className={`filter-control ${this.state.toggled ? "on" : "off"}`}>
          <button
            onClick={this.onToggle}
            className={`filter-toggle ${this.state.toggled ? "on" : ""}`}
          >
            <div className="off">
              <span className="icon" style={{ marginRight: `var(--gutter)` }}>
                +
              </span>
              <span>Filter</span>
            </div>
            <div className="on">
              <Close />
            </div>
          </button>
          <div role="presentation" onClick={this.onFilter}>
            <ul className="filter-list">
              <Trail
                native
                config={{ tension: 595, clamp: true }}
                reverse={!this.state.toggled}
                initial={null}
                items={labels}
                from={{ opacity: 0, x: -100, height: 0, dspl: 0 }}
                keys={item => item.label}
                to={{
                  opacity: this.state.toggled ? 1 : 0,
                  x: this.state.toggled ? 0 : 100,
                  height: this.state.toggled ? `auto` : 0,
                  dspl: this.state.toggled ? 1 : 0,
                }}
              >
                {item => ({ x, opacity, height, dspl }) => (
                  <animated.li
                    className={`${item.active ? "active" : "inactive"}`}
                    style={{
                      opacity: !item.active ? opacity : "",
                      transform: !item.active
                        ? x.interpolate(x => `translate3d(${x}%,0,0)`)
                        : "",
                      height,
                      display: dspl.interpolate(displ =>
                        filterOn && !item.active && displ === 0
                          ? "none"
                          : "inherit"
                      ),
                      // margin: !item.active && !this.state.toggled ? 0 : `inherit`
                    }}
                  >
                    <button
                      className={`filter-item ${item.active ? "active" : ""}`}
                    >
                      {item.label}
                    </button>
                  </animated.li>
                )}
              </Trail>
            </ul>
          </div>
        </div>
      </aside>
    )
  }
}

export default Filter
