/* eslint-disable no-mixed-operators */

import React, { useState } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { CSSTransition } from "react-transition-group"
import { useMediaQuery } from "beautiful-react-hooks"

import Logo from "./logo"
import Close from "../svgs/close.svg"
import Circle from "../svgs/menu-circle.svg"
import LogoH from "../svgs/logo-h.svg"

import "./header.css"

const Header = ({ isScrolled, siteLinks, scrollToTop, contactEmail }) => {
  const isSmall = useMediaQuery("(max-width: 800px)")
  const getInitialState = () => {
    if (isSmall) {
      return false
    } else return !isScrolled
  }

  const [menuNavShown, setMenuNavShown] = useState(() => getInitialState())
  const [isScrollingDown, setIsScrollingDown] = useState(isScrolled)
  const [isSmallScreen, setIsSmallScreen] = useState(isSmall)

  if (isScrollingDown !== isScrolled) {
    setIsScrollingDown(isScrolled)
    if (!isSmallScreen) {
      setMenuNavShown(!isScrolled)
    }
  }

  if (isSmallScreen !== isSmall) {
    setIsSmallScreen(isSmall)
    setMenuNavShown(!isSmall)
  }

  const showNav = () => {
    setMenuNavShown(true)
  }

  const hideNav = () => {
    setMenuNavShown(false)
  }

  function shouldDisplayMenu() {
    return !shouldDisplayNav()
  }

  function shouldDisplayNav() {
    if (isSmallScreen) {
      return menuNavShown
    } else if (!isSmallScreen) {
      return menuNavShown
    }
  }

  function shouldDisplayLogo() {
    if (isSmallScreen) {
      return shouldDisplayMenu()
    } else if (!isSmallScreen) {
      return isScrolled || shouldDisplayNav()
    }
  }

  function shouldDisplayClose() {
    return (isScrolled && menuNavShown) || (isSmallScreen && menuNavShown)
  }

  return (
    <header className={`header ${isScrolled ? "scrolled" : "not-scrolled"}`}>
      <CSSTransition
        in={shouldDisplayLogo()}
        timeout={100}
        unmountOnExit
        classNames="menu-nav"
      >
        <button
          aria-label="Scroll to top"
          onClick={scrollToTop}
          style={{
            margin: 0,
          }}
        >
          <LogoH className="logo-here" />
          <CSSTransition timeout={50} key={isScrolled} classNames="fade">
            <Logo isScrolled={isScrolled} isSmallScreen={isSmallScreen}></Logo>
          </CSSTransition>
          <span className="sr-only">Midnight Bloom homepage</span>
        </button>
      </CSSTransition>
      <nav className={`${menuNavShown ? "open" : "closed"}`}>
        <div
          className={`desktop-nav${menuNavShown ? " open" : " closed"}${
            isScrolled ? " scrolled" : ""
          }`}
        >
          <CSSTransition
            in={shouldDisplayNav()}
            timeout={200}
            unmountOnExit
            classNames="menu-nav"
          >
            <ul
              style={{
                listStyleType: `none`,
                marginTop: "0.5em",
                position: `relative`,
              }}
            >
              {siteLinks?.map(sl => (
                <li>
                  <a
                    href={sl.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {sl.title}
                  </a>
                </li>
              ))}
              <li>
                <TransitionLink
                  className="link"
                  to="/about"
                  exit={{ length: 0 }}
                  entry={{ length: 1, delay: 0.1 }}
                >
                  About
                </TransitionLink>
              </li>
              <li>
                <a className="link" href={`mailto:${contactEmail}`}>
                  <span className="letters-hide">Contact</span>
                </a>
              </li>
            </ul>
          </CSSTransition>
          <CSSTransition
            in={shouldDisplayClose()}
            timeout={200}
            unmountOnExit
            classNames="menu-nav"
          >
            <button onClick={hideNav} className="close-button">
              <span>Close</span>
              <Close className="icon-close" />
            </button>
          </CSSTransition>
        </div>
        <CSSTransition
          in={shouldDisplayMenu()}
          timeout={100}
          unmountOnExit
          classNames="menu-nav"
        >
          <div className="menu-trigger-container">
            <button onClick={showNav} className="menu-trigger">
              <span>Menu</span>
              <Circle className="menu-circle" />
            </button>
          </div>
        </CSSTransition>
      </nav>
    </header>
  )
}

export default Header
