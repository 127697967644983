import React from 'react';
import { animated, useSprings } from "react-spring";
import './logo.css';

const Logo = ({ isScrolled }) => {
  const toV = () => ({ opacity: 1, transform: `skewX(0deg) translateX(0px)` })
  const fromV = i => ({ opacity: 0, transform: `skewX(${-10 * i}deg) translateX(${-10 * i}px)` })
  const [propsV] = useSprings(13, i => ({ ...toV(i), from: fromV(i), config: {duration: 150 } })) // Create a bunch of springs using the helpers above

  return (
    <animated.svg className={`lg ${isScrolled ? 'scrolled' : 'not-scrolled'}`} viewBox={!isScrolled ? "-3 -3 612 119": "-3 -3 148 623"} version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="logo">
        <rect x="0" y="28.731" width="28.731" height="86.193"></rect>
        <path d="M97.366,0 L82.6,0 L82.6,28.415 C75.3797336,11.2151745 58.5538443,0.0182741957 39.9,0 L28.731,0 L28.731,28.731 L39.9,28.731 C49.5940087,28.7398175 57.4509743,36.5949936 57.462,46.289 L57.462,114.924 L86.193,114.924 L86.193,46.289 C86.198595,40.2662233 85.0223762,34.3008713 82.731,28.731 L97.366,28.731 C107.058447,28.7420217 114.912978,36.5965534 114.924,46.289 L114.924,114.924 L143.655,114.924 L143.655,46.289 C143.626343,20.7361708 122.918829,0.0286571995 97.366,0 Z" id="arches"></path>
      </g>
      {!isScrolled &&
        <g id="wordmark-h" transform="translate(165.000000, 36.000000)" fill="#000000">
          <animated.path style={propsV[0]} d="M15.8154907,27.1988536 L15.7688658,27.1988536 C14.6589669,23.0094827 10.5434709,10.6678225 7.12166172,1 L0,1 L0,33 L4.43902699,33 L4.43902699,20.4766117 C4.43902699,14.9970278 4.34691448,7.99908004 4.06943976,4.31526431 L4.25423337,4.31526431 C5.68766325,9.93241809 10.6822083,24.7118392 13.3642744,33 L17.5718829,33 C21.1329981,22.9624938 25.9871,9.79428207 27.3756108,4.36112094 L27.5604044,4.36112094 C27.4216671,7.76866464 27.3295545,13.8460831 27.3295545,19.6936523 L27.3295545,32.9977355 L32,32.9977355 L32,1 L25.1103253,1 C21.5031539,10.3932489 16.60129,23.9294459 15.8154907,27.1988536 Z"></animated.path>
          <animated.rect style={propsV[1]} x="44" y="1" width="5" height="32"></animated.rect>
          <animated.path style={propsV[2]} d="M71.7368488,1 L60,1 L60,33 L71.5514641,33 C81.0157815,33 87,26.0931993 87,16.65466 C87,7.16969783 80.8766003,1 71.7368488,1 Z M71.2268982,29.0404784 L64.7783623,29.0404784 L64.7783623,5.00367985 L71.2736722,5.00367985 C78.8350868,5.00367985 81.9900494,10.6208336 81.9900494,16.7446748 C81.9900494,23.7913099 78.5567246,29.0404784 71.2268982,29.0404784 L71.2268982,29.0404784 Z"></animated.path>
          <animated.path style={propsV[3]} d="M118.590042,12.9255537 C118.590042,18.1741561 118.544293,23.2857547 118.865665,27.2447102 L118.819916,27.2447102 C117.074119,23.7913099 107.612026,8.4593447 103.156319,1 L97,1 L97,33 L101.456271,33 L101.456271,20.7987404 C101.456271,14.9975939 101.409958,9.38044017 101.180649,5.88118321 L101.272147,5.88118321 C103.201503,9.51857618 113.123344,26.1860449 117.21193,32.9988677 L123,32.9988677 L123,1 L118.589477,1 L118.590042,12.9255537 Z"></animated.path>
          <animated.rect style={propsV[4]} x="135" y="1" width="5" height="32"></animated.rect>
          <animated.path style={propsV[5]} d="M164.841898,3.92311264 C169.912384,3.92311264 172.28756,6.55403267 173.292334,9.83076526 L178,9.83076526 C177.132266,4.15578676 172.427408,0 164.936254,0 C155.252457,0 150,7.24638005 150,16.8461737 C150,25.9828891 154.886268,33 164.06852,33 C169.549565,33 172.335861,30.7385211 173.79781,28.4305073 C173.79683,29.8128618 173.950001,31.1909266 174.254423,32.5386242 L177.817467,32.5386242 L177.817467,15.0920378 L164.659365,15.0920378 L164.659365,19.1536199 L173.201348,19.1536199 L173.201348,19.4765262 C173.201348,24.4137575 171.556304,29.03092 164.704858,29.03092 C157.716372,29.03092 154.932322,23.0307653 154.932322,16.6157696 C154.930076,9.78479794 157.625386,3.92311264 164.841898,3.92311264 Z"></animated.path>
          <animated.polygon style={propsV[6]} points="209.335123 14.2146345 193.664877 14.2146345 193.664877 1 189 1 189 33 193.664877 33 193.664877 18.2200127 209.335123 18.2200127 209.335123 33 214 33 214 1 209.335123 1"></animated.polygon>
          <animated.polygon style={propsV[7]} points="223 5.051801 233.128343 5.051801 233.128343 33 237.872779 33 237.872779 5.051801 248 5.051801 248 1 223 1"></animated.polygon>
          <animated.path style={propsV[8]} d="M291.394548,16.0098365 C293.823042,15.1351638 295.970795,12.740995 295.970795,9.05774538 C295.970795,4.68381572 292.795956,1 285.745286,1 L273,1 L273,33 L286.120181,33 C292.378002,33 296.999604,29.500743 296.999604,23.6073172 C297.034526,20.1331254 294.757386,17.0465319 291.394548,16.0098365 L291.394548,16.0098365 Z M277.714034,4.86780836 L285.511623,4.86780836 C289.106712,4.86780836 291.114382,6.75528979 291.114382,9.47215342 C291.114382,12.4188663 289.386879,14.1710424 284.951289,14.1710424 L277.714034,14.1710424 L277.714034,4.86780836 Z M285.418043,29.1321916 L277.714034,29.1321916 L277.714034,18.0360201 L284.7182,18.0360201 C289.900709,18.0360201 292.048464,20.2439318 292.048464,23.5608945 C292.051333,27.3364235 289.433382,29.1321916 285.418043,29.1321916 Z"></animated.path>
          <animated.polygon style={propsV[9]} points="311.681881 1 307 1 307 33 327.363975 33 328 28.9940556 311.681881 28.9940556"></animated.polygon>
          <animated.path style={propsV[10]} d="M349.184723,0 C339.446342,0 334,7.29234738 334,16.5232674 C334,25.7076526 339.261336,33 348.815277,33 C358.969638,33 364,25.199742 364,16.3388306 C364,6.73846948 358.277283,0 349.184723,0 Z M348.999716,28.9843852 C342.354236,28.9843852 338.984394,23.1692347 338.984394,16.3859329 C338.984394,9.64746346 342.030759,4.01447979 348.999716,4.01447979 C355.831338,4.01447979 359.015606,9.64576096 359.015606,16.4301978 C359.015606,23.0319003 356.200216,28.9843852 348.999716,28.9843852 Z"></animated.path>
          <animated.path style={propsV[10]} d="M388.184436,0 C378.446239,0 373,7.29234738 373,16.5232674 C373,25.7076526 378.261804,33 387.815564,33 C397.969734,33 403,25.199742 403,16.3388306 C403,6.73846948 397.277391,0 388.184436,0 Z M388,28.9843852 C381.354078,28.9843852 377.984867,23.1692347 377.984867,16.3859329 C377.984867,9.64746346 381.031174,4.01447979 388,4.01447979 C394.830925,4.01447979 398.015701,9.64576096 398.015701,16.4301978 C398.015701,23.0319003 395.200363,28.9843852 388,28.9843852 Z"></animated.path>
          <animated.path style={propsV[12]} d="M438.110448,1 C434.50334,10.3932489 429.602701,23.9294459 428.81521,27.1988536 L428.769154,27.1988536 C427.659275,23.0094827 423.543852,10.6678225 420.122104,1 L413,1 L413,33 L417.439517,33 L417.439517,20.4766117 C417.439517,14.9970278 417.347406,7.99908004 417.069367,4.31526431 L417.254158,4.31526431 C418.688131,9.93241809 423.679176,24.7118392 426.364606,33 L430.572139,33 C434.133191,22.9624938 438.987207,9.79428207 440.375693,4.36112094 L440.561052,4.36112094 C440.422317,7.76866464 440.329638,13.8460831 440.329638,19.6936523 L440.329638,32.9977355 L445,32.9977355 L445,1 L438.110448,1 Z"></animated.path>
        </g>
      }
      {isScrolled &&
        <g id="wordmark-v" transform="translate(73.500000, 387.767467) rotate(-270.000000) translate(-73.500000, -387.767467) translate(-159.000000, 370.000000)">
          <animated.path style={propsV[0]} d="M16.559,28.4457839 L16.5101833,28.4457839 C15.3481064,24.0594645 11.0391349,11.1376048 7.45646146,1.01528384 L-2.88561024e-14,1.01528384 L-2.88561024e-14,34.5196507 L4.64771214,34.5196507 L4.64771214,21.4075196 C4.64771214,15.6703324 4.55126929,8.34340087 4.26075007,4.48640358 L4.45423111,4.48640358 C5.95504862,10.367628 11.1843945,25.8418513 13.9925485,34.5196507 L18.3979629,34.5196507 C22.1264913,24.0102666 27.2087916,10.222998 28.6625783,4.53441599 L28.8560593,4.53441599 C28.7107997,8.10215331 28.6143569,14.4652801 28.6143569,20.587752 L28.6143569,34.5172797 L33.5043668,34.5172797 L33.5043668,1.01528384 L26.2907985,1.01528384 C22.5140487,10.8501231 17.3817409,25.0226765 16.559,28.4457839 Z"></animated.path>
          <animated.rect style={propsV[1]} id="rectangle-2" x="47.2106987" y="1.52292576" width="4.06113537" height="33.5043668"></animated.rect>
          <animated.path style={propsV[2]} d="M74.8638312,1.01528384 L62.9475983,1.01528384 L62.9475983,34.5196507 L74.6756131,34.5196507 C84.2845816,34.5196507 90.360262,27.2881512 90.360262,17.4058923 C90.360262,7.47502819 84.1432733,1.01528384 74.8638312,1.01528384 Z M74.3460866,30.3739861 L67.7989923,30.3739861 L67.7989923,5.20718254 L74.3935755,5.20718254 C82.0705576,5.20718254 85.2737401,11.0884069 85.2737401,17.5001389 C85.2737401,24.8780465 81.7879409,30.3739861 74.3460866,30.3739861 L74.3460866,30.3739861 Z"></animated.path>
          <animated.path style={propsV[3]} d="M124.291483,13.5014753 C124.291483,18.9968222 124.243248,24.3487245 124.582081,28.4937963 L124.533846,28.4937963 C122.693195,24.8780465 112.716996,8.82530325 108.019196,1.01528384 L101.528384,1.01528384 L101.528384,34.5196507 L106.226779,34.5196507 L106.226779,21.7447919 C106.226779,15.6709252 106.177949,9.78970076 105.936181,6.12593862 L106.03265,6.12593862 C108.066835,9.93433075 118.527761,27.3853615 122.838494,34.5184652 L128.941048,34.5184652 L128.941048,1.01528384 L124.290887,1.01528384 L124.291483,13.5014753 Z"></animated.path>
          <animated.rect style={propsV[4]} id="rectangle-1" x="141.632096" y="1.52292576" width="4.06113537" height="33.5043668"></animated.rect>
          <animated.path style={propsV[5]} d="M172.49879,4.10377205 C178.014485,4.10377205 180.598211,6.85584601 181.691208,10.2834722 L186.812227,10.2834722 C185.868303,4.34716082 180.750339,2.88561024e-14 172.60143,2.88561024e-14 C162.067356,2.88561024e-14 156.353712,7.580076 156.353712,17.6219403 C156.353712,27.1794016 161.669014,34.5196507 171.657506,34.5196507 C177.619809,34.5196507 180.650753,32.1540306 182.241067,29.7397327 C182.240001,31.1857447 182.406621,32.6272694 182.737773,34.0370285 L186.613667,34.0370285 L186.613667,15.7870265 L172.30023,15.7870265 L172.30023,20.0356445 L181.592234,20.0356445 L181.592234,20.3734206 C181.592234,25.5380115 179.802748,30.3677945 172.349717,30.3677945 C164.747613,30.3677945 161.719112,24.0913325 161.719112,17.3809261 C161.716669,10.2353881 164.648638,4.10377205 172.49879,4.10377205 Z"></animated.path>
          <animated.polygon style={propsV[6]} points="218.944466 15.3587996 202.398329 15.3587996 202.398329 1.52292576 197.472707 1.52292576 197.472707 35.0272926 202.398329 35.0272926 202.398329 19.5524765 218.944466 19.5524765 218.944466 35.0272926 223.870087 35.0272926 223.870087 1.52292576 218.944466 1.52292576"></animated.polygon>
          <animated.polygon style={propsV[7]} points="233.007642 5.76520786 243.702111 5.76520786 243.702111 35.0272926 248.711737 35.0272926 248.711737 5.76520786 259.405022 5.76520786 259.405022 1.52292576 233.007642 1.52292576"></animated.polygon>
          <animated.path style={propsV[8]} d="M305.493373,17.2383967 C307.958984,16.3226043 310.139563,13.8158821 310.139563,9.95947754 C310.139563,5.37992305 306.9162,1.52292576 299.757769,1.52292576 L286.817686,1.52292576 L286.817686,35.0272926 L300.138394,35.0272926 C306.491858,35.0272926 311.184096,31.3635304 311.184096,25.1930461 C311.219552,21.5555274 308.907608,18.3238286 305.493373,17.2383967 L305.493373,17.2383967 Z M291.603768,5.57256546 L299.520534,5.57256546 C303.17057,5.57256546 305.208925,7.54878014 305.208925,10.3933675 C305.208925,13.4786097 303.455019,15.3131581 298.951637,15.3131581 L291.603768,15.3131581 L291.603768,5.57256546 Z M299.425524,30.9776529 L291.603768,30.9776529 L291.603768,19.3598341 L298.714985,19.3598341 C303.976702,19.3598341 306.157284,21.6715429 306.157284,25.1444409 C306.160196,29.097463 303.502233,30.9776529 299.425524,30.9776529 Z"></animated.path>
          <animated.polygon style={propsV[9]} points="325.301845 1.52292576 320.322052 1.52292576 320.322052 35.0272926 341.981801 35.0272926 342.658297 30.8330229 325.301845 30.8330229"></animated.polygon>
          <animated.path style={propsV[10]} d="M364.680697,0.507641921 C354.463903,0.507641921 348.75,8.13580204 348.75,17.7918061 C348.75,27.3991325 354.269808,35.0272926 364.293102,35.0272926 C374.946313,35.0272926 380.223799,26.8678326 380.223799,17.598876 C380.223799,7.55641805 374.219944,0.507641921 364.680697,0.507641921 Z M364.486602,30.8267586 C357.514652,30.8267586 353.97926,24.7438204 353.97926,17.6481474 C353.97926,10.5993713 357.175283,4.70698859 364.486602,4.70698859 C371.653838,4.70698859 374.994539,10.5975904 374.994539,17.6944506 C374.994539,24.6001616 372.040838,30.8267586 364.486602,30.8267586 Z"></animated.path>
          <animated.path style={propsV[11]} d="M406.307033,0.507641921 C396.090432,0.507641921 390.376638,8.13580204 390.376638,17.7918061 C390.376638,27.3991325 395.896936,35.0272926 405.920041,35.0272926 C416.57305,35.0272926 421.850437,26.8678326 421.850437,17.598876 C421.850437,7.55641805 415.846695,0.507641921 406.307033,0.507641921 Z M406.113537,30.8267586 C399.141124,30.8267586 395.606394,24.7438204 395.606394,17.6481474 C395.606394,10.5993713 398.802356,4.70698859 406.113537,4.70698859 C413.280042,4.70698859 416.621275,10.5975904 416.621275,17.6944506 C416.621275,24.6001616 413.66763,30.8267586 406.113537,30.8267586 Z"></animated.path>
          <animated.path style={propsV[12]} d="M457.78656,1.01528384 C454.009877,10.8501231 448.878851,25.0226765 448.054339,28.4457839 L448.006118,28.4457839 C446.844062,24.0594645 442.535167,11.1376048 438.952557,1.01528384 L431.495633,1.01528384 L431.495633,34.5196507 L436.143858,34.5196507 L436.143858,21.4075196 C436.143858,15.6703324 436.047417,8.34340087 435.756308,4.48640358 L435.949785,4.48640358 C437.451171,10.367628 442.676852,25.8418513 445.488528,34.5196507 L449.893864,34.5196507 C453.622327,24.0102666 458.704537,10.222998 460.158298,4.53441599 L460.35237,4.53441599 C460.207113,8.10215331 460.110077,14.4652801 460.110077,20.587752 L460.110077,34.5172797 L465,34.5172797 L465,1.01528384 L457.78656,1.01528384 Z"></animated.path>
        </g>
      }
    </animated.svg>
  );
}

export default Logo