import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Scrollbar from "react-smooth-scrollbar"
import { DisableScrollPlugin, AnchorPlugin } from "./scroll-plugins"
import SmoothScrollbar from "smooth-scrollbar"

import Header from "./header"
import Footer from "./footer"
import Content from "./content"
import Player from "./player"
import { controlsFullScreenVolume } from "../utils/vimeo-controls"
import { getVimeoLink } from "../utils/url"

import "./home.css"

SmoothScrollbar.use(AnchorPlugin, DisableScrollPlugin)

const Home = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    {
      contentfulGeneralSettings(id: { eq: "a0d4505b-b555-551d-bad7-f565b4d40fe2" }) {
        socialMediaAccounts {
          title
          url
        }
        telephone
        contactEmail
        footerReccomendations {
          ... on ContentfulCompanyRecommendations {
            recommendation
            recommendationContext
            recommendationUrl
          }
        }
      }
      contentfulHomepage(id: { eq: "7e918322-1218-55a3-8d8d-7e6341d9f72d" }) {
        heading
        featuredProject {
          id
          brand
          projectTitle
          vimeoLink
        }
        siteLinks {
          ... on ContentfulSiteLinks {
            title
            url
          }
        }
        mainVideosList {
          __typename
          id
          brand
          year
          projectTitle
          condensedVideo {
            file {
              url
            }
          }
          projectImage {
            fluid {
              src
            }
            fixed {
              height
              width
            }
          }
          label
        }
        content {
          __typename
          ... on Node {
            ... on ContentfulProject {
              id
              brand
              year
              projectTitle
              condensedVideo {
                file {
                  url
                }
              }
              projectImage {
                fluid {
                  src
                }
                fixed {
                  height
                  width
                }
              }
              label
            }
          }
          ... on Node {
            ... on ContentfulMediaBlock {
              id
              title
              image {
                id
                fluid {
                  src
                }
              }
            }
          }
          ... on Node {
            ... on ContentfulJobOpening {
              id
              description {
                json
              }
              supplementaryText {
                json
              }
              internal {
                type
              }
            }
          }
          ... on Node {
            ... on ContentfulPlainTextBlock {
              id
              title
              description {
                json
              }
              supplementaryText {
                json
              }
              internal {
                type
              }
              image {
                description
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const { featuredProject } = data.contentfulHomepage
  const projectHash = location && location.state && location.state.projectHash
  let isMobile = false
  if (typeof window !== "undefined") {
    if (navigator && navigator.userAgent) {
      isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false
    }
  }
  const scrollbarEl = useRef(null)

  const [state, setState] = useState({
    scrolled: false,
  })

  const [playing, setPlaying] = useState(true)

  useEffect(() => {
    if (!scrollbarEl || !scrollbarEl.current) return
    const { scrollbar } = scrollbarEl && scrollbarEl.current
    if (!scrollbar) return
    const player = document.getElementById("home-player")
    let playerHeight

    if (player) {
      playerHeight = player.offsetHeight
    }

    const handleScroll = status => {
      const isScrolled = scrollbar.offset.y > playerHeight
      if (isScrolled !== state.scrolled) {
        setState(prevState => {
          return {
            ...prevState,
            scrolled: !state.scrolled,
          }
        })
      }
      const offset = status.offset
      const fixedElem = document.querySelector(".filter-container")

      if (fixedElem) {
        const style = getComputedStyle(fixedElem).position
        if (style === "fixed") {
          fixedElem.style.top = offset.y + "px"
          fixedElem.style.right = 0 + "px"
        }
      }
    }

    scrollbar.addListener(handleScroll)
    return () => {
      // clean up the event handler when the component unmounts
      scrollbar.removeListener(handleScroll)
    }
  }, [state])

  useEffect(() => {
    const heroContainer = document.querySelector(".hero-container")
    const mouseTakeover = document.querySelector(".mouse-takeover")
    if (mouseTakeover && window) {
      heroContainer.addEventListener("mousemove", e => {
        mouseTakeover.style.left = `${e.pageX}px`
        mouseTakeover.style.top = `${e.pageY}px`
      })
    }
  })

  const base = (
    <React.Fragment>
      <div className="hero-container" style={{ position: "relative", cursor: playing ? "pointer" : "none" }}>
        <span
          className="mouse-takeover"
          style={{
            position: `absolute`,
            display: playing ? "none" : "block",
            zIndex: 10,
            top: `50%`,
            left: `50%`,
            height: `2em`,
            width: `4em`,
            marginLeft: `-2em`,
            marginTop: `-1em`,
            pointerEvents: "none",
          }}
        >
          PLAY
        </span>

        <Player
          controls={controlsFullScreenVolume}
          options={{
            clickToPlay: true,
            loop: {
              active: true,
            },
          }}
          onPlaying={bool => setPlaying(bool)}
          id="home-player"
          title={featuredProject.projectTitle}
          vimeoLink={getVimeoLink(featuredProject.vimeoLink)}
        />
      </div>

      <Content isScrolled={state.scrolled} data={data} />
      <Footer
        telephone={data.contentfulGeneralSettings.telephone}
        contactEmail={data.contentfulGeneralSettings.contactEmail}
        socialMediaAccounts={data.contentfulGeneralSettings.socialMediaAccounts}
        companyRecs={data.contentfulGeneralSettings.footerReccomendations}
      />
    </React.Fragment>
  )

  function scrollToTop() {
    if (!scrollbarEl || !scrollbarEl.current) return
    const { scrollbar } = scrollbarEl && scrollbarEl.current
    if (!scrollbar) return
    const el = document.getElementById("home-player")
    requestAnimationFrame(function () {
      if (el && scrollbar) {
        scrollbar.scrollIntoView(el)
      }
    })
  }

  return (
    <>
      {children}
      <Header
        contactEmail={data.contentfulGeneralSettings.contactEmail}
        isScrolled={state.scrolled}
        scrollToTop={scrollToTop}
        siteLinks={data.contentfulHomepage.siteLinks}
      />
      {!isMobile ? (
        <Scrollbar
          damping={0.075}
          ref={scrollbarEl}
          className="scroll-container"
          plugins={{
            disableScroll: { direction: "x" },
            anchor: { hash: projectHash },
          }}
        >
          {base}
        </Scrollbar>
      ) : (
        <>{base}</>
      )}
    </>
  )
}

Home.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Home
