import React, { useState } from "react"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import TransitionLink from "gatsby-plugin-transition-link"
import "./home.css"
import "./footer.css"
import Arrow from "../svgs/arrow-right.svg"
import Close from "../svgs/close.svg"

const Footer = ({ socialMediaAccounts, companyRecs, telephone, contactEmail }) => {
  const [siteInfoShown, setShowSiteInfo] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const showSiteInfo = () => {
    setShowSiteInfo(true)
  }

  const hideSiteInfo = () => {
    setShowSiteInfo(false)
  }

  const validateInput = () => {
    const inputV = document.querySelector(".newsletter-input")
    const invalidEls = document.querySelectorAll(":invalid")
    return [...invalidEls].indexOf(inputV) < 0 || /^.+@.+\..+$/.test(inputV.value)
  }

  const submitEmail = e => {
    e.preventDefault()
    if (!validateInput(e)) {
      return
    }

    const nForm = document.getElementById("newsletter-form")
    const formData = new FormData(nForm)
    fetch(nForm.getAttribute("action"), {
      method: "POST",
      cache: "no-cache",
      mode: "no-cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      body: formData,
    })
      .then(result => {
        setFormSubmitted(true)
      })
      .catch(error => {
        console.error("Error:", error)
      })
  }

  const resetForm = e => {
    e.preventDefault()
    setFormSubmitted(false)
    const input = document.getElementById("mce-EMAIL")
    input.value = ""
  }

  return (
    <footer>
      <div>
        <section>
          <div className="newsletter">
            <span>Our NEWSLETTER</span>
            <div>
              <form
                id="newsletter-form"
                action="https://midnightbloom.us5.list-manage.com/subscribe/post?u=0ca1c0b53d0028b6063a92889&amp;id=53e6a8deb9"
                method="get"
                className="input-container"
              >
                <input
                  className="newsletter-input"
                  aria-label="Enter your email"
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  onChange={() => {}}
                  placeholder="Your EMAIL"
                />
                {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                <div style={{ position: `absolute`, left: `-5000px` }} aria-hidden="true">
                  <input type="text" name="b_2ab7454ddbca18a2a1fdb45cc_2282fc29fb" tabIndex="-1" defaultValue="" />
                </div>
                <SwitchTransition>
                  <CSSTransition
                    key={formSubmitted}
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false)
                    }}
                    classNames="fade"
                  >
                    {!formSubmitted ? (
                      <button
                        type="submit"
                        onClick={submitEmail}
                        aria-labelledby="newsletter-submit-label"
                        className="newsletter-submit"
                      >
                        <Arrow className="arrow-right" />
                        <span id="newsletter-submit-label" className="sr-only">
                          Submit email for newsletter
                        </span>
                      </button>
                    ) : (
                      <span>
                        <span role="alert" className="sr-only">
                          Email submitted. Clear email input.
                        </span>
                        <button className="done" onClick={resetForm}>
                          <span className="sr-only">Email submitted. Clear email input.</span>
                        </button>
                      </span>
                    )}
                  </CSSTransition>
                </SwitchTransition>
              </form>
            </div>
          </div>
          <div>
            <h4>Contact</h4>
            {/* this container div is chaning line heights n stuff */}
            <div className="contact">
              <span>
                <a className="link" href={`mailto:${contactEmail}`}>
                  {contactEmail}
                </a>
              </span>
              <span>{telephone}</span>
            </div>
          </div>
        </section>
        <section className="recs">
          {companyRecs.map(rec => (
            <article key={rec.recommendation}>
              <h4>{rec.recommendationContext}</h4>
              <a className="inline-link" href={rec.recommendationUrl}>
                {rec.recommendation}
              </a>
            </article>
          ))}
        </section>
        <section className="spacer"></section>
        <section className="station">
          <div style={{ gridArea: `nav` }}>
            <h4>This Site</h4>
            <ul>
              <li>
                <TransitionLink className="link" to="/about" exit={{ length: 0 }} entry={{ length: 1, delay: 0.1 }}>
                  About
                </TransitionLink>
              </li>
              <li>
                <a className="link" href={`mailto:${contactEmail}`}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div style={{ gridArea: `social` }}>
            <h4>Online</h4>
            <ul>
              {socialMediaAccounts.map(social => (
                <li key={social.title}>
                  <a className="link" target="_blank" rel="noreferrer" href={social.url}>
                    {social.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <small style={{ gridArea: `credit-1` }} className="ttu">
            © Midnight Bloom
          </small>
          <button
            onClick={showSiteInfo}
            className="site-credit-trigger"
            style={{ gridArea: `credit-2`, padding: `0`, marginBottom: `0` }}
            href="#site-credit"
          >
            <small className="link">Site Credit</small>
          </button>
        </section>
      </div>
      <div className={`site-credit ${siteInfoShown ? "show" : "hide"}`}>
        <div>
          Design:{" "}
          <a target="_blank" className="inline-link" rel="noopener noreferrer" href="https://www.alright.studio">
            Alright Studio
          </a>
        </div>
        <span>•</span>
        <div>
          Development:{" "}
          <a target="_blank" className="inline-link" rel="noopener noreferrer" href="https://catangui.com/">
            Sean
          </a>
          &nbsp;&amp;&nbsp;
          <a target="_blank" className="inline-link" rel="noopener noreferrer" href="https://www.omayeli.com/">
            Yeli
          </a>
        </div>
        <span>•</span>
        <button onClick={hideSiteInfo} className="close-button">
          Close <Close className="icon-close" />
        </button>
      </div>
    </footer>
  )
}

export default Footer
