/* eslint-disable no-mixed-operators */
import React, { Component } from "react"
import { InView } from "react-intersection-observer"
import { Transition } from "react-spring/renderprops"
import PropTypes from "prop-types"
import Video from "./video"
import PlainText from "./plain-text"
import Filter from "./filter"

class Content extends Component {
  constructor(props) {
    super(props)
    const { data } = props
    this.heading = data.contentfulHomepage.heading
    this.state = {
      featuredContent: data.contentfulHomepage.content || [],
      filterableContent: data.contentfulHomepage.mainVideosList || [],
      filteredData: [],
      filters: {
        all: 1,
      },
    }

    this.onFilter = this.onFilter.bind(this)
  }

  static contextTypes = {
    getScrollbar: PropTypes.func,
  }

  onFilter(e) {
    let { target } = e
    let { filters } = this.state

    if (target.nodeName === "BUTTON") {
      // If a filter item was clicked
      let filterOption = target.textContent

      if (filterOption === "All") {
        this.setState({
          filteredData: this.state.featuredContent,
        })
      }

      if (filterOption !== "All") {
        // Turn off all filters
        filters = {}
        // Turn on active filter
        filters[filterOption] = 1

        const filteredData = this.state.filterableContent.filter(item => {
          const labels = item.label
          if (labels) {
            return Object.keys(filters).some(l => labels.indexOf(l) > -1)
          }
          return false
        })

        this.setState({
          filters,
          filteredData,
        })

        let context = this.context
        if (context && context.getScrollbar) {
          requestAnimationFrame(function () {
            context.getScrollbar(scrollbar => {
              const el = document.querySelector(".callout-container")
              if (el && scrollbar) {
                const elHeight = el.offsetHeight
                scrollbar.scrollIntoView(el, {
                  offsetTop: elHeight,
                  offsetLeft: 5000,
                })
              }
            })
          })
        }
      }
    }

    if (Object.keys(filters).length < 1) {
      this.setState({
        filters: {
          all: 1,
        },
      })
    }
  }

  render() {
    const { filteredData, filterableContent, featuredContent, filters } = this.state
    const activeFilters = Object.keys(filters)
    const filterOn = activeFilters.length > 0 && activeFilters.indexOf("all") < 0
    const hasFilteredResults = filteredData && filterOn
    const data = hasFilteredResults ? filteredData : featuredContent

    let allLabels = ["all"]

    filterableContent.forEach(proj => {
      if (proj.label) allLabels.push(...proj.label)
    })

    const labels = Array.from(new Set(allLabels)).map(label => {
      return {
        label,
        active: activeFilters.indexOf(label) > -1,
      }
    })

    return (
      <div style={{ position: `relative` }}>
        <main className="work">
          <InView>
            {({ inView, ref }) => (
              <div ref={ref} className="callout-container">
                <Transition items={inView} from={{ opacity: 0 }} enter={{ opacity: 1 }} leave={{ opacity: 0 }}>
                  {inView =>
                    inView &&
                    (props => (
                      <p style={props} className="callout" dangerouslySetInnerHTML={{ __html: this.heading }} />
                    ))
                  }
                </Transition>
              </div>
            )}
          </InView>
          <Filter isScrolled={this.props.isScrolled} labels={labels} onFilter={this.onFilter} />
          <div className="vertical-line"></div>
          {data.map((node, i) => {
            if (node.__typename === "ContentfulProject") {
              return <Video key={`project-${node.projectTitle}-${i}`} project={node} context={this.context} />
            }

            if (node.__typename === "ContentfulPlainTextBlock") {
              return (
                <PlainText
                  key={`job-${node.title}`}
                  title={node.title}
                  primaryText={node.description.json}
                  secondaryText={node.supplementaryText.json}
                  image={node.image}
                  document={node}
                />
              )
            }

            if (node.__typename === "ContentfulMediaBlock") {
              return (
                <div key={`non-project-${node.id}`} className="non-project">
                  <img className="video-slice" alt={node.title} key={`media-${node.id}`} src={node.image.fluid.src} />
                </div>
              )
            }

            if (node.__typename === "ContentfulJobOpening") {
              return (
                <div key={`non-project-${node.id}`} className="non-project">
                  <PlainText
                    key={`job-${node.id}`}
                    title="Job Openings"
                    primaryText={node.description.json}
                    secondaryText={node.supplementaryText.json}
                    document={node}
                  />
                </div>
              )
            }

            return null
          })}
        </main>
      </div>
    )
  }
}

export default Content
